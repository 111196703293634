<template>
  <div class="mb-5">
  <b-overlay :show="loading"> 
    <div class="w-100 d-flex justify-content-between">
      <b-button variant="warning" @click="toBack">
        {{$t('message.back')}}
      </b-button>
    </div>

    <ValidationObserver ref="refFormObserver">
      <b-card class="mt-2" v-if="!$route.params.id && !$route.params.insuranceId">
        <!-- Client  -->
        <b-row>
          <b-col md="12">
            <h4>
              {{$t('message.client_text')}}
            </h4>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.passport')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.passport')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="book"></b-icon>
                  <input 
                    class="form-control"
                    v-model="clientModel.passport_serial" 
                    maxlength="2" 
                    placeholder="AA" 
                    style="width: 50px;" 
                    type="text"
                    @input="toLoware();changePassort()"
                  />
                  <input 
                    class="form-control"
                    v-model="clientModel.passport_number" 
                    ref="passportNumber"  
                    placeholder="1234567" 
                    v-mask="'#######'"
                    type="text"
                    @input="changePassort"
                  />
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.given_date')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.given_date')}}
                </p>
                <div class="wrapper-form">
                  <input type="date" class="form-control" v-model="clientModel.given_date">
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.expire_date')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.expire_date')}}
                </p>
                <div class="wrapper-form">
                  <input type="date" class="form-control" v-model="clientModel.expire_date" >
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider #default="validationContext" :name="$t('data.firstNmae')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.firstNmae')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="person"></b-icon>
                  <input 
                    class="form-control"
                    v-model="clientModel.first_name"
                    @input="toUpperCaseFirstname"
                  />
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider #default="validationContext" :name="$t('data.lastName')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.lastName')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="person"></b-icon>
                  <b-form-input 
                    class="form-control"
                    v-model="clientModel.last_name"
                    @input="toUpperCaseLastname"
                  />
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.date_birthday')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.date_birthday')}}
                </p>
                <div class="wrapper-form">
                  <input type="date" class="form-control" v-model="clientModel.birthdate">
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.file')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="file-earmark"></b-icon>
                <input type="file" class="form-control" @change="fileChange">
              </div>
            </div>
          </b-col>

         

          <b-col md="4">
            <ValidationProvider #default="validationContext"  :name="$t('data.mobile_number')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.mobile_number')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="telephone"></b-icon>
                  <b-form-input v-model="number1" v-mask="'############'" type="number"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Client mobile number additional -->
          <b-col md="4">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.mobile_number_additional')}}
              </p>
              <div class="wrapper-form">
                <b-icon icon="telephone"></b-icon>
                <b-form-input v-model="number2" type="number" v-mask="'############'"></b-form-input>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="mt-2">
        <b-row>
          <!-- Data to_country_id -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.where_region')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.where_region')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="arrow-up-right-square"></b-icon>
                  <v-select
                    v-model="dataModel.to_country_id"
                    label="name_ru"
                    :pushTags="true"
                    taggable
                    :options="countryOptions"
                    @search="searchCountry"
                    @option:selected="selectedToCountry"
                    @search:blur="selectTempCountry"
                  ></v-select>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Data After date  -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.afterDate')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.afterDate')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="calendar2-check"></b-icon>
                  <input
                    v-model="dataModel.start_date"
                    type="date"
                    class="form-control"
                  >
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Data Before date  -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.beforeDate')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.beforeDate')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="calendar2-check"></b-icon>
                  <input
                    type="date"
                    v-model="dataModel.end_date"
                    class="form-control"
                  >
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Avia_sales actual_price -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.sum_gross_air')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.sum_gross_air')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <b-form-input v-model="dataModel.market_price" type="number"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <!-- Avia_sales market_price -->
          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.sum_total_air')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.sum_total_air')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <b-form-input v-model="dataModel.actual_price" type="number"></b-form-input>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.payment_methods')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.payment_methods')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <v-select
                    v-model="dataModel.currency_id"
                    :options="currencies"
                    label="name"
                  ></v-select>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>

          <b-col md="4">
            <ValidationProvider #default="validationContext" :name="$t('data.payment_type')" rules="required">
              <div class="wrapper">
                <p class="wrapper-title">
                  {{$t('data.payment_type')}}
                </p>
                <div class="wrapper-form">
                  <b-icon icon="wallet2"></b-icon>
                  <v-select v-model="dataModel.payment_type_id" :options="paymentType" label="name_ru"></v-select>
                </div>
                <span class="text-danger">
                  {{ validationContext.errors[0] }}
                </span>
              </div>
            </ValidationProvider>
          </b-col>
          <b-col md="4">
            <div class="wrapper">
              <p class="wrapper-title">
                {{$t('data.is_legal_collection')}}
              </p>
              <div class="wrapper-form border-0" style="padding: 7px !important">
                <b-form-checkbox
                  class="px-2"
                  id="checkbox-1"
                  v-model="dataModel.is_legal_collection"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <div class="w-100 d-flex justify-content-end">
        <b-button variant="primary" :disabled="disabledItem" @click="handleAdd">
          {{$t('message.Add')}}
        </b-button>
      </div>
    </ValidationObserver>
    </b-overlay> 
  </div>
</template>

<script>
  import {ValidationProvider, ValidationObserver} from 'vee-validate'
  import {required,alphaNum} from '@validations';
  import {getClients} from '@/api/clients/clients';
  import {getCountries} from '@/api/countries/countries';
  import {mapGetters,mapActions} from 'vuex'
  import moment from 'moment'
  import {
    addInsurance,
    updateInsurance,
    showInsurance
  } from '@/api/insurance'

  export default {
    name: "insurance-insuranceAdd",
    components: {
      ValidationProvider,
      ValidationObserver
    },
    data() {
      return {
        loading: false,
        required,
        alphaNum,
        number1:null,
        number2:null,
        disabledItem: false,
        file: null,
        clientModel: {
          id: null,
          first_name: '',
          last_name: '',
          birthdate: null,
          passport_serial: '',
          passport_number: '',
          given_date: null,
          expire_date: null,
          contacts: [{number: ''}, {number: ''}]
        },
        dataModel: {
          id: null,
          client_id: null,
          to_country_id: null,
          end_date: null,
          start_date: null,
          actual_price: null,
          market_price: null,
          currency_id: null,
          payment_type_id: null,
          is_legal_collection: true,
        },
        insurance: {
          file: null,
          client: {
            id: null,
            first_name: '',
            last_name: '',
            birthdate: null,
            passport_serial: '',
            passport_number: '',
            contacts: [{
                number: ''
              },
              {
                number: ''
              }
            ],
            given_date: null,
            expire_date: null,
          },
          data: {
            id: null,
            client_id: null,
            to_country_id: null,
            end_date: null,
            start_date: null,
            actual_price: null,
            market_price: null,
            currency_id: null,
            payment_type_id: null,
            is_legal_collection: false,
          },
        },
        clientsOptions: [],
        timeout: null,
        countryOptions: [],
        countryTimeout: null,
        fisrtNameOptions: [],
        firstNameTimiout: null,
        lastNameOptions: [],
        lastNmaeTimeout: null,
        surNameOptions: [],
        surNameTimeout: null,
        tempFirstname: null,
        tempLastname: null,
        tempCountry: null,
        dataTimeout: null,
      }
    },
    methods: {
      ...mapActions('settings', ['fetchCurrencies','fetchMethods']),
      toBack() {
        this.$router.go(-1)
      },
      handleAdd() {
        const isValid = this.$refs.refFormObserver.validate();
        this.loading = true
        this.loading = true
        if (isValid) {
          // disabled button
          this.disabledItem = true;
          setTimeout( () => {
            this.disabledItem = false
          this.loading = false

          }, 1000)
          // client
          if(!this.$route.params.id) {
            this.insurance.client.id = this.clientModel.id;
            this.insurance.client.contacts = [];
            this.insurance.client.contacts.push({number:this.number1},{number:this.number2})
            this.insurance.client.contacts = JSON.stringify(this.insurance.client.contacts)
            this.insurance.client.first_name = this.clientModel.first_name.first_name
            this.insurance.client.last_name = this.clientModel.last_name
            this.insurance.client.birthdate = moment(this.clientModel.birthdate).format('YYYY-MM-DD')
            this.insurance.client.passport_serial = this.clientModel.passport_serial
            this.insurance.client.passport_number = this.clientModel.passport_number
            this.insurance.client.given_date = moment(this.clientModel.given_date).format('YYYY-MM-DD')
            this.insurance.client.expire_date = moment(this.clientModel.expire_date).format('YYYY-MM-DD')
          } else {
            this.insurance.client = {}
            this.insurance.client.id = this.$route.params.id
          }

          // ticket
          this.insurance.data.id = this.dataModel.id
          this.insurance.data.client_id = parseInt(this.dataModel.client_id)
          this.insurance.data.to_country_id = this.dataModel.to_country_id.id  ? parseInt(this.dataModel.to_country_id.id) : this.dataModel.to_country_id.name_ru // select
          this.insurance.data.end_date = moment(this.dataModel.end_date).format('YYYY-MM-DD')
          this.insurance.data.start_date = moment(this.dataModel.start_date).format('YYYY-MM-DD')
          this.insurance.data.actual_price = this.dataModel.actual_price
          this.insurance.data.market_price = this.dataModel.market_price
          this.insurance.data.currency_id = this.dataModel.currency_id.id // select
          this.insurance.data.payment_type_id = this.dataModel.payment_type_id.id // select
          this.insurance.data.is_legal_collection = this.dataModel.is_legal_collection

          if(this.$route.params.insuranceId) {
            updateInsurance(this.insurance.data).then(() => {
              this.toBack();
        this.loading = false

            })
          } else {
            let fd = new FormData();

            fd.append('client', JSON.stringify(this.insurance.client));
            fd.append('data', JSON.stringify(this.insurance.data));
            fd.append('passport_file', this.insurance.file)

            addInsurance(fd).then(() => {
              this.toBack();
        this.loading = false

            })
          }
        }
      },
      selectTempCountry(item) {
        if(this.tempCountry) {
          if(this.dataModel.to_country_id === null) {
            // this.clientModel.first_name = this.tempFirstname
            this.dataModel.to_country_id = {id:null,name_ru: this.tempCountry}
          }
        }
      },
      searchCountry(item) {
        if (item.length > 0) {
          this.tempCountry = item
          clearTimeout(this.countryTimeout);
          this.countryTimeout = setTimeout(() => {
            getCountries({search: item}).then(res => {
              this.countryOptions = res.data.data.data
            })
          }, 500)
        }
      },
      selectedToCountry(item) {
        if(typeof item === 'object') {
          this.dataModel.to_country_id = item
        }
        else {
          this.dataModel.to_country_id = {name_ru:item,id:null}
        }
      },
      fetchShowData() {
        if (this.$route.params.insuranceId) {
          showInsurance(this.$route.params.insuranceId).then(response => {
            let res = response.data.data;
            this.dataModel.id = res.id;
            this.dataModel.client_id = res.client_id
            this.dataModel.to_country_id = res.country;
            this.dataModel.end_date = moment(res.end_date).format('YYYY-MM-DD');
            this.dataModel.start_date = moment(res.start_date).format('YYYY-MM-DD');
            this.dataModel.actual_price = parseInt(res.actual_price)
            this.dataModel.market_price = parseInt(res.market_price)
            this.dataModel.currency_id = res.currency;
            this.dataModel.payment_type_id = res.payment_type;
            this.dataModel.is_legal_collection = res.is_legal_collection
          })
        }
      },
      fileChange(e) {
        this.file = e.target.files[0]
      },
      toLoware() {
        this.clientModel.passport_serial = this.clientModel.passport_serial.toUpperCase();
        if(this.clientModel.passport_serial.length >= 2) {
          this.$refs.passportNumber.focus()
        }
      },
      changeFirstName() {
        if(this.tempFirstname) {
          if(this.clientModel.id === null) {
            // this.clientModel.first_name = this.tempFirstname
            this.clientModel.first_name = {id:null,first_name: this.tempFirstname}
          }
        }
      },
      searchFirstName(item) {
        if (item.length > 0) {
          this.tempFirstname = item;
          clearTimeout(this.firstNameTimeout);
          this.firstNameTimeout = setTimeout(() => {
            getClients({
              search: item
            }).then(res => {
              this.fisrtNameOptions = res.data.data
            })
          }, 500)
        }
      },
      selectedFirstName(item) {
        if (item.id) {
          this.dataModel.client_id = item.id
          this.clientModel.id = item.id;
          this.clientModel.first_name = item.first_name
          this.clientModel.last_name = item.last_name
          this.clientModel.passport_serial = item.passport_serial
          this.clientModel.passport_number = item.passport_number
          this.clientModel.birthdate = item.birthdate
          this.clientModel.expire_date = item.expire_date
          this.clientModel.given_date = item.given_date
          this.clientModel.contacts = JSON.parse(item.contacts);
          if (!this.clientModel.contacts[0]) {
            this.clientModel.contacts = new Array(this.clientModel.contacts)
          }
          if (this.clientModel.contacts.length === 1) {
            this.number1 = this.clientModel.contacts[0].number
          }
          if (this.clientModel.contacts.length === 2) {
            this.number1 = this.clientModel.contacts[0].number
            this.number2 = this.clientModel.contacts[1].number
          }
        } else {
          this.clientModel = {id: null,first_name:item}
        }
      },
      // changeLastname() {
      //   if(this.tempLastname) {
      //     if(this.clientModel.id === null) {
      //       this.clientModel.last_name = {last_name:this.tempLastname}
      //     }
      //   }
      // },
      // searchLastName(item) {
      //   if (item.length > 0) {
      //     this.tempLastname = item
      //     clearTimeout(this.lastNmaeTimeout);
      //     this.lastNmaeTimeout = setTimeout(() => {
      //       getClients({
      //         search: item
      //       }).then(res => {
      //         this.lastNameOptions = res.data.data
      //       })
      //     }, 500)
      //   }
      // },
      // selectedLastName(item) {
      //   if (item.id) {
      //     this.dataModel.client_id = item.id
      //     this.clientModel.id = item.id;
      //     this.clientModel.first_name = item.first_name
      //     this.clientModel.last_name = item.last_name
      //     this.clientModel.passport_serial = item.passport_serial
      //     this.clientModel.passport_number = item.passport_number
      //     this.clientModel.birthdate = item.birthdate
      //     this.clientModel.expire_date = item.expire_date
      //     this.clientModel.given_date = item.given_date
      //     this.clientModel.contacts = JSON.parse(item.contacts);
      //     if (!this.clientModel.contacts[0]) {
      //       this.clientModel.contacts = new Array(this.clientModel.contacts)
      //     }
      //     if (this.clientModel.contacts.length === 1) {
      //       this.number1 = this.clientModel.contacts[0].number
      //     }
      //     if (this.clientModel.contacts.length === 2) {
      //       this.number1 = this.clientModel.contacts[0].number
      //       this.number2 = this.clientModel.contacts[1].number
      //     }
      //   } else {
      //     this.clientModel.last_name = item
      //   }
      // },
      changePassort() {
        if(this.clientModel.passport_serial.length > 0 && this.clientModel.passport_number.length > 0) {
          clearTimeout(this.dataTimeout);
          this.dataTimeout = setTimeout(() => {
            getClients({passport_serial: this.clientModel.passport_serial, passport_number: this.clientModel.passport_number}).then( response => {
              let res = response.data.data[0];
              this.clientModel.id = res.id;
              this.clientModel.first_name       = res.first_name;
              this.clientModel.last_name        = res.last_name;
              this.clientModel.birthdate        = res.birthdate;
              this.clientModel.passport_serial  = res.passport_serial;
              this.clientModel.passport_number  = res.passport_number;
              this.clientModel.given_date       = res.given_date;
              this.clientModel.expire_date      = res.expire_date;
              this.clientModel.contacts = JSON.parse(res.contacts);
              if (!this.clientModel.contacts[0]) {
                this.clientModel.contacts = new Array(this.clientModel.contacts)
              }
              if (this.clientModel.contacts.length === 1) {
                this.number1 = this.clientModel.contacts[0].number
              }
              if (this.clientModel.contacts.length === 2) {
                this.number1 = this.clientModel.contacts[0].number
                this.number2 = this.clientModel.contacts[1].number
              }
            })
          }, 200)
        }
      },
      toUpperCaseFirstname() {
        this.clientModel.first_name = this.clientModel.first_name.toUpperCase(); 
      },
      toUpperCaseLastname() {
        this.clientModel.last_name = this.clientModel.last_name.toUpperCase(); 
      },
    },
    created() {
      this.fetchCurrencies()
      this.fetchMethods(); // payment methods
    },
    mounted() {
      this.fetchShowData()
    },
    computed: {
      ...mapGetters('settings', ['currencies', 'paymentType'])
    },
  }
</script>
